<template>
  <div>
    <inputText
      :data="title"
      :label="label[0]"
      :callback="function (value) { setTitle(value); }"
      :placeholder="label[2]"
      :help="label[1]"
      :error="getError()"
      :styling="{ input: 'width: 100%; max-width: 500px; display: block' }"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: JSON.parse(JSON.stringify(this.$store.state.business.unsaved))
        .title,
      label: this.getLabel()
    };
  },
  methods: {
    getLabel() {
      let label = [
        this.$t("design.name.title"),
        this.$t("design.name.info"),
        this.$t("design.name.placeholder")
      ];
      let data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      if (data.type === "eventpicker") {
        label = [
          this.$t("settings.event.name.title"),
          this.$t("settings.event.name.info"),
          this.$t("settings.event.name.placeholder")
        ];
      }
      return label;
    },
    setTitle(value) {
      this.title = value;
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.title = value;
      this.$store.commit("business/prefetch", data);
    },
    getError() {
      if (this.title || !this.$store.state.save.trySave) {
        return false;
      } else {
        return this.$t("design.name.error");
      }
    }
  }
};
</script>
<style lang="sass" scoped></style>
